import React from "react";
import questionMark from "../../assets/questionmark.png";
import quality from "../../assets/Quality.png";
import infrastructure from "../../assets/Infrastructure.png";
import "./WhyUs.css";

function WhyUs() {
  return (
    <div className="wu-container">
      <div className="wu-box">
        <div className="image">
          <img src={questionMark} alt="heart" />
        </div>
        <div className="content">
          <h1>Why Us</h1>
          <hr className="hry-heading-bar" />
          <p>
            Some of the factors that have consistently fueled our growth in the
            international market are:
            <ul>
              <li>International quality standards</li>
              <li>Customized solution</li>
              <li>Competitive pricing</li>
              <li>Highly focused research & development</li>
              <li>Excellent after sales services</li>
              <li>On time delivery</li>
            </ul>
          </p>
        </div>
      </div>
      <div className="wu-box">
        <div className="content">
          <h1>Quality Compliance</h1>
          <hr className="hry-heading-bar" />
          <p>
            As a very well known firm we are absolutely prone to high quality
            end products. We ensure that we use standardized raw material for
            our production processes. We have attained certificate of ISO
            9001:2008. We are keeping a strict and stringent quality check on
            our production process. Our expert team of quality is consistently
            working and keeping a watch over each stage of production. Keeping
            in mind our clients need pure and perfect products our team of
            experts is keeping minute check on each product before delivering to
            the client.{" "}
          </p>
        </div>
        <div className="image">
          <img src={quality} alt="quality" />
        </div>
      </div>
      <div className="wu-box">
        <div className="image">
          <img src={infrastructure} alt="infrastructure" />
        </div>
        <div className="content">
          <h1>Infrastructure</h1>
          <hr className="hry-heading-bar" />
          <p>
            We are very well equipped with state of art infrastructure. Our
            production work takes place in a spacious and advanced manufacturing
            plant well furnished with all sorts of facilities. We make it a
            point we are using hi-tech machinery to make fast and accurate
            production. Since we are providing hundred percent customization to
            each of our product as per client's specifications, we tend to use
            latest technology and software at every step to provide complete
            customized equipment. We aim at updating our machinery from time to
            time.
          </p>
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
