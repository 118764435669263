import React from "react";
import { Link } from "react-router-dom";
import { FaArrowCircleUp } from "react-icons/fa";
import AxeemO from "../../assets/AxeemO.png";
import "./Footer.css";

const handleScroll = () => {
  window.scrollTo(0, 0);
};

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="footer-inner">
          <div className="brand">
            <Link to="/">
              <img
                src={AxeemO}
                onClick={() => {
                  handleScroll();
                }}
                alt="Axeem Lab"
              />
            </Link>
            <div className="trademark">
              <p>A registered trademark of</p>
              <h4>Onish Scientific Industries</h4>
            </div>
          </div>
          <div className="quick-links">
            <h4>Quick Links</h4>
            <div>
              <Link
                to="/"
                onClick={() => {
                  handleScroll();
                }}
                className="p"
              >
                Home
              </Link>
              <Link to="/products" className="p">
                Products
              </Link>
              <Link to="/who-we-are" className="p">
                About Us
              </Link>
            </div>
          </div>
          <div className="address">
            <h4>Registered Address</h4>
            <p>
              2774/1, Timber Market, Near D.C. Road, Ambala Cantt, Haryana,
              India - 133001
            </p>
            <p>Mb: +91 98960 53548</p>
            <p>Email id: axeemlab@gmail.com</p>
          </div>
        </div>
        <hr />
        <div className="footer-end">
          © Copyrights AxeemLab 2021. All rights reserved. <br /> Designed By
          &nbsp;
          <a
            href="https://brioodev.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Brioodev.com
          </a>
        </div>
        <button className="scroll-btn">
          <FaArrowCircleUp onClick={handleScroll} />
        </button>
      </div>
    </>
  );
}

export default Footer;
