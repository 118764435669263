import React from "react";
import oops from "../../assets/Oops.svg";
import "./PageNotFound.css";

function PageNotFound() {
  return (
    <div className="pnf-container">
      <img className="pnf-img" src={oops} alt="404" />
      <div className="pnf-content">
        <h3 className="pnf-heading-1">Error |</h3>
        <h3 className="pnf-heading-2">Page Not Found</h3>
      </div>
    </div>
  );
}

export default PageNotFound;
