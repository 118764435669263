import { useState, useEffect } from "react";
import sheetdb from "sheetdb-node";

//SheetDb Config file
const configuration = {
  address: "m3sk612au5gx3",
  auth_login: "az6llzdf",
  auth_password: "vqgygq2id8vv5cyp0klk",
};
const client = sheetdb(configuration);

let current = new Date();

function useForm(validateData, sheetName) {
  const [data, setData] = useState({
    name: "",
    email: "",
    mobileNo: "",
    subject: "",
    message: "",
    timeStamp:
      current.getFullYear() +
      "-" +
      (current.getMonth() + 1) +
      "-" +
      current.getDate() +
      " | " +
      current.getHours() +
      ":" +
      current.getMinutes() +
      ":" +
      current.getSeconds(),
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  //Setting input values to data state
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  //function to validate data on click of submit button
  const onFormSubmit = (e) => {
    e.preventDefault();
    setErrors(validateData(data));
    setIsSubmitting(true);
  };

  //checks for errors
  useEffect(() => {
    const submitForm = (data) => {
      // Adds single row
      client.create(data, sheetName).then(
        (res) => {
          console.log(res);
          setIsSubmitted(true);
        },
        (err) => {
          console.log(err);
        }
      );
    };

    if (Object.keys(errors).length === 0 && isSubmitting) {
      submitForm(data, sheetName);
      setIsSubmitting(false);
    }
  }, [errors, isSubmitting, data, sheetName]);

  //Submitting data to sheet after client side validation.

  return { handleChange, data, onFormSubmit, errors, isSubmitted };
}

export default useForm;
