import React from "react";
import heartBg from "../../assets/heartBg.jpg";
import customerService from "../../assets/CustomerService.png";
import blockPricing from "../../assets/block-pricing.png";
import quality from "../../assets/Quality.png";
import guides from "../../assets/guides.png";
import "./SalientFeatures.css";

function SalientFeatures() {
  return (
    <div className="sf-container">
      <img src={heartBg} alt="heart" className="heartBg" />
      <h1>Our Salient Features</h1>
      <hr className="heading-bar" />
      <div className="features">
        <div className="feature">
          <img src={customerService} alt="customer service" />
          <p>On-site Customer Service</p>
        </div>
        <div className="feature">
          <img src={blockPricing} alt="fair pricing" />
          <p>Fair Pricing</p>
        </div>
        <div className="feature">
          <img src={quality} alt="Standardized Quality Managment System" />
          <p>Standardized Quality Managment System</p>
        </div>
        <div className="feature">
          <img src={guides} alt="guides" />
          <p>Installation Guides</p>
        </div>
      </div>
    </div>
  );
}

export default SalientFeatures;
