import React from "react";
import useForm from "../useForm";
import validateData from "../ValidateData";
import atom from "../../assets/atom.png";
import tick from "../../assets/Tick.svg";
import "./Contact.css";

function Contact() {
  const { handleChange, data, onFormSubmit, errors, isSubmitted } = useForm(
    validateData,
    "contact_us"
  );

  const { name, email, mobileNo, subject, message } = data;

  return (
    <>
      <div className="contact-container">
        <div className="contact-heading">
          <h1>Contact Us</h1>
          <hr className="heading-bar" />
        </div>
        <div className="container">
          <div className="left-container">
            <img src={atom} alt="atom" className="atom-bg" />
            <h2>Interested in placing an order or learning more?</h2>
            <h3>Simply reach out today!</h3>
            <p>
              <span>Office Address:</span> 2774/1, Timber Market, Near DC Road,
              Ambala Sadar, Haryana 133001, India
            </p>
            <p>
              <span>Email id:</span> axeemlab@gmail.com
            </p>
            <p>
              <span>Mb:</span> +91 98960 53548
            </p>
          </div>

          {/* FORM */}

          <div className="right-container">
            {!isSubmitted ? (
              <form className="form" onSubmit={onFormSubmit}>
                <h2>Get In Touch</h2>
                <h3>Fill the form below!</h3>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={handleChange}
                  />
                  {errors.name && <small>{errors.name}</small>}
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                  />
                  {errors.email && <small>{errors.email}</small>}
                </div>
                <div className="form-group">
                  <label htmlFor="mobile">Mobile Number</label>
                  <input
                    type="tel"
                    name="mobileNo"
                    value={mobileNo}
                    onChange={handleChange}
                  />
                  {errors.mobileNo && <small>{errors.mobileNo}</small>}
                </div>
                <div className="form-group">
                  <label htmlFor="subject">Subject</label>
                  <input
                    type="text"
                    name="subject"
                    value={subject}
                    onChange={handleChange}
                  />
                  {errors.subject && <small>{errors.subject}</small>}
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea
                    name="message"
                    value={message}
                    onChange={handleChange}
                    cols="22"
                    rows="5"
                  ></textarea>
                  {errors.message && <small>{errors.message}</small>}
                </div>
                <div className="form-group">
                  <button type="submit">Submit</button>
                </div>
              </form>
            ) : (
              <div className="form-success">
                <img src={tick} width="100px" alt="" />
                <h4>Details Successfully Submitted.</h4>
                <h5>Our team will contact you soon.</h5>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="location-container">
        <h1>Location</h1>
        <hr className="heading-bar" />
        <div className="location">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3443.501452623465!2d76.84180211508962!3d30.33669558177689!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fb6140f5f69d1%3A0x8e46577c6733194e!2sOnish%20Scientific%20Industries!5e0!3m2!1sen!2sin!4v1627286223118!5m2!1sen!2sin"
            title="Location"
            className="map"
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default Contact;
