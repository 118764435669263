import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/Header/Header";
import HomeFront from "./components/HomeFront/HomeFront";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Quote from "./components/Quote/Quote";
import Certifications from "./components/Certifications/Certifications";
import SalientFeatures from "./components/SalientFeatures/SalientFeatures";
import WhoWeAre from "./components/WhoWeAre/WhoWeAre";
import HereForYou from "./components/HereForYou/HereForYou";
import WhyUs from "./components/WhyUS/WhyUs";
import Products from "./components/Products/Products";
import ProductPage from "./components/ProductPage/ProductPage";
import PageNotFound from "./components/404/PageNotFound";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Switch>
        <Route path="/" exact component={HomeFront} />
        <Route path="/why-us" component={WhyUs} />
        <Route path="/contact" component={Contact} />
        <Route path="/quote" component={Quote} />
        <Route path="/certifications" component={Certifications} />
        <Route path="/salient-features" component={SalientFeatures} />
        <Route path="/who-we-are" component={WhoWeAre} />
        <Route path="/here-for-you" component={HereForYou} />
        <Route path="/products" exact component={Products} />
        <Route path="/products/:id" component={ProductPage} />
        <Route component={PageNotFound} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
