import React from "react";
import "./WhoWeAre.css";

function WhoWeAre() {
  return (
    <div className="whoweare-bg">
      <div className="whoweare">
        <h1>Who We Are</h1>
        <hr className="heading-bar" />
        <p>
          Being ISO 9001:2008 certified, we, Onish Scientific Industries, are a
          preferred organization to associate with. Our company is taking hard
          core initiatives towards providing excellent services to our clients.
          We are ardently committed in giving valuable and efficient high
          quality products to our clients. Moreover, we are engaged into
          manufacturing and exporting scientific and laboratory instruments such
          as electrostatics instruments, optics instruments, electronics,
          mechanic instruments, magnetism and many more. More preference is
          given on the quality and strength of the equipment so produced. We are
          putting our hundred per cent efforts in making our clients fully
          satisfied. We have been constantly working on standard quality
          products making ourselves a highly running business. We are actively
          conducting ourselves for providing utmost contribution towards the
          research and other medical institutions.
        </p>
      </div>

      <div className="history">
        <h1>History</h1>
        <hr className="heading-bar" />
        <p>
          Established in 1994, ONISH SCIENTIFIC INDUSTRIES is efficiently
          engrossed in manufacturing and exporting scientific and laboratory
          instruments. Our company produces instruments for properties – solids,
          fluids, gases, waves & sound, optics, light, heat etc. We are running
          ourselves as a proprietorship firm in Haryana. As we are a globally
          recognized concern we have become a very successful and highly trusted
          association.
        </p>
        <p>
          We adhere to a speculated production from the first phase of
          manufacturing to the final stage of delivery. Since we are working for
          the research institutes, hospital industries and many more, we are
          taking enough care for the changes as desired by our customers. We are
          constantly working to develop and upgrade our products to utmost
          quality. Our first priority is to make conscious initiatives towards
          satisfying our clients.{" "}
        </p>
      </div>
      <div className="whoweare">
        <h1>Team & Management</h1>
        <hr className="heading-bar" />
        <p>
          Our team consists of efficient management & administration experts,
          skilled workmen quality control specialists and R&D and other
          professionals. The brilliant minds working for the company are very
          experienced and trained consequently making it to a roaring business.
          Our workforce comprises of an excellent technical personnel. With
          hundred percent cooperation and commitment towards the concern we have
          been a very successful team so far. Each teammate takes hard core
          initiative towards the establishment adhering to the all the
          principles of the company. The staff has been working hard to
          manufacture the best material with full customization for our clients.
        </p>
      </div>
      <div className="history">
        <h1>Product Profile</h1>
        <hr className="heading-bar" />
        <p>
          Our company expertise into manufacturing and exporting a vast range of
          high quality laboratory apparatus. We are an established name in
          producing standardized research and medical equipment therefore we
          pledge to provide full satisfaction to the client. Being certified
          with ISO 9001:2008 we promise to give absolutely safe and easily
          maintained equipment. Our products comprise outstanding features
          consequently we become one of the most sort after concern. Some of the
          striking attributes of our products which make us a preferred firm are
          as follows:
          <ul>
            <li>Corrosion proof</li>
            <li>Use of high quality raw material</li>
            <li>Cost effective</li>
            <li>Durable</li>
            <li>Strong</li>
            <li>Safe</li>
            <li>Easy in use</li>
            <li>Free from damages</li>
          </ul>
        </p>
      </div>
    </div>
  );
}

export default WhoWeAre;
