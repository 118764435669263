import React from "react";
import { Link } from "react-router-dom";
import useInfo from "../useInfo";
import "./Products.css";

function Products() {
  const { products } = useInfo();
  return (
    <div>
      <div className="p-heading-div">
        <h1>Products</h1>
        <hr className="heading-bar" />
      </div>
      <div className="p-product-list">
        {products.map((product) => {
          return (
            <div key={product.modelNo} className="p-product">
              <div className="p-product-imgdiv">
                <img
                  src={product.image}
                  className="p-product-image"
                  alt="product"
                />
              </div>
              <div className="p-product-body">
                <h2>{product.productName}</h2>
                <p>{product.modelNo}</p>
              </div>
              <div className="p-product-footer">
                <Link to="/quote">
                  <button className="enquiry-btn">Enquiry</button>
                </Link>
                <Link to={`/products/${product.modelNo}`}>
                  <button className="info-btn">Info</button>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Products;
