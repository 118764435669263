import React from "react";
import { Link } from "react-router-dom";
import microscope from "../../assets/microscope.jpg";
import "./HereForYou.css";

function HereForYou() {
  return (
    <div className="hry-container">
      <div className="hry-left-container">
        <h1>Here For You</h1>
        <hr className="hry-heading-bar" />
        <p>
          At <span>Onish Scientific Industries,</span> you won’t only find what
          you need; you’ll get the best quality at a fair price. With years of
          experience in the industry, we have the expertise to thoroughly and
          efficiently serve our customers to ensure they’re never disappointed.
        </p>
        <p>
          Whether you’re a one-time buyer or looking to buy in bulk, you can
          count on us to provide great products and excellent service, every
          step of the way. Browse our products below and get in touch if you
          can’t find what you’re looking for.
        </p>
        <Link to="/products">
          <button className="hry-btn">All Products</button>
        </Link>
      </div>
      <div className="hry-right-container">
        <img src={microscope} alt="microscope" />
      </div>
    </div>
  );
}

export default HereForYou;
