function useInfo() {
  const products = [
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/axeemlab-2752d.appspot.com/o/products%2FOven.svg?alt=media&token=c2a5a1b3-cce4-4dc0-af14-eac5951be150",
      productName: "Hot Air Oven",
      modelNo: "OU-1001",
      description:
        "Axeem Hot Air Oven Temperature up to 200°C. Heating elements are placed in ribs at the bottom and sides are built In L-Shape thermometer. Double-walled inside Aluminium and outside Mild steel Sheet Powder Coated with perforated adjustable 2 shelves, two indicators, Thermostat & Low Medium High Rotary switch. To work on 220/230 V AC controls Heating. Supplied with or without air circulating fan.",
      table:
        "https://firebasestorage.googleapis.com/v0/b/axeemlab-2752d.appspot.com/o/table%2FTable.png?alt=media&token=57e0d761-20d8-475c-97ca-1fc00fa953ce",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/axeemlab-2752d.appspot.com/o/products%2FIncubator.svg?alt=media&token=033d30d9-a187-430e-bfa2-e265bc740c26",
      productName: "Incubator",
      modelNo: "IB-1002",
      description:
        "Axeem Incubator has temperature upto 70°C. Thermostatic control with a sensitivity of + 0.50C. Heating elements are placed in ribs at the bottom and sides. Double-walled inside anodized. Aluminium or Stainless steel, outside Mild steel. Built-in horizontal LShape thermometer with perforated adjustable shelves, two indicators, thermostat and On/off switch. To work on 220 volts A.C. Supplied. with or without air circulation fan.",
      table:
        "https://firebasestorage.googleapis.com/v0/b/axeemlab-2752d.appspot.com/o/table%2FTable.png?alt=media&token=57e0d761-20d8-475c-97ca-1fc00fa953ce",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/axeemlab-2752d.appspot.com/o/products%2FBOD.svg?alt=media&token=207e6586-aadc-4c55-a099-050c10b57cc2",
      productName: "BOD",
      modelNo: "BO-1121",
      description:
        "Axeem B.O.D. Incubator Temperature range 50°C to 500°C with an accuracy of +10°C. Double walled inside anodized Aluminium and outside Mild steel sheet with 'L' shape thermometer and two air circulating fans. To work on 220/230 volts A.C.Available in 4, 6.1, 10 and 12 cu. ft. Capacities with manual (Economy) or automatic temperature control (Deluxe) & Super (Electronic Temp. Control). Electronic digital read-out, timer and interior illumination can also be supplied. With unbreakable Acrylic transport door inside.",
      table: "",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/axeemlab-2752d.appspot.com/o/products%2FWaterBath.svg?alt=media&token=ee1b9856-d80b-49de-af3e-9f05725ee279",
      productName: "Water Bath",
      modelNo: "WR-1051",
      description:
        "Axeem Water bath Rectangular (Thermostatically Controlled) Double Wall, Temperature range from ambient temperature to 80ºC. Thermostatic control with an accuracy of +1ºC. Double walled inside Stainless steel and outside Mild steel sheet. Top of the bath 75mm holes with concentric rings. Heating is attained by an immersion heater. Supplied Complete with plug & Cord. To work on 220/230 volts.",
      table: "",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/axeemlab-2752d.appspot.com/o/products%2FHotPlate.svg?alt=media&token=ce444c1e-eae9-46a2-8554-8410e28e1dce",
      productName: "Hot Plate",
      modelNo: "HP-1020",
      description:
        "Axeem Hot Plates Mounted on thick M.S. Sheets body a smooth surface cast iron top, heated with an electric element of Oxidised Kanthal Wire laid under the plate. A Three step Rotary Switch works on 220/230 V AC controls Heating. The top is finished with Heat Resistant black and the body is finished with stoving paint.",
      table: "",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/axeemlab-2752d.appspot.com/o/products%2FDryBath.svg?alt=media&token=ec4bdb4a-0d07-4ae4-bfb4-a718e58ca3ee",
      productName: "Dry Bath Incubator",
      modelNo: "DB-1001",
      description:
        "Axeem Dry Bath Incubator Digital / Dry Block is versatile dry bath providing controlled dry heat for a wide variety of clinical & general chemistry applications. Our newly designed unparalleled accuracy, rehabilitee & flexibility in application. It has unique auto tuned microprocessor based temperature controller or With Digital Temp. Controller & Digital Timer which controls the key parameters of temperature. While granting user more control, than any other block heater.",
      table: "",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/axeemlab-2752d.appspot.com/o/products%2FFurnace.svg?alt=media&token=a0e53287-9d8d-41b2-8c38-4d6a8156062c",
      productName: "Furnace",
      modelNo: "MH-1016",
      description:
        "Axeem Muffle Furnace (high temp.) Maximum Temperature range upto 1200°C. Working temperature range 1100°C with grooved refractories. Fitted with Energy Regulator. Thermocouple complete with pyrometer.",
      table: "",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/axeemlab-2752d.appspot.com/o/products%2FHumidityChamber.svg?alt=media&token=58b699f2-61e4-4401-b64d-423ea85c4bd8",
      productName: "Humidity Chamber",
      modelNo: "HE-1099",
      description:
        "Axeem Humidity Chamber Temperature range from ambient temperature to 600°C. Temperature controlled by a hydraulic type thermostat with an accuracy of +10°C, built-in horizontal 'L' shape thermometer. The humidity is controlled by imported Humiditistat from atmospheric humidity to 95% with a sensitivity of +3%.Double-walled inside Stainless Steel and outside Mild steel sheet.The door has glass window. To work on 220/230 volts A.C. with Hygrometer. Inside water tank is made of stainless steel.",
      table: "",
    },
  ];
  return { products };
}

export default useInfo;
