import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Typewriter } from "react-simple-typewriter";
import useInfo from "../useInfo";
import Bg from "../../assets/homefront_Bg.jpg";
import HomefrontImage from "../../assets/homefront_img.png";
import Lab from "../../assets/Lab-full-width.png";
import msme from "../../assets/msme.svg";
import nsic from "../../assets/nsic.svg";
import iso from "../../assets/iso.svg";
import dandb from "../../assets/d&b.svg";
import "./HomeFront.css";

function HomeFront() {
  const { products } = useInfo();
  const featuredProducts = products.slice(0, 4);
  const about = useRef();

  return (
    <>
      <div className="homefront">
        <img src={Bg} alt="Bg" className="bg" />
        <div className="left">
          <img
            src={HomefrontImage}
            alt="Microscope"
            className="homefront-img"
          />
        </div>
        <div className="right">
          <div className="typewrite-heading">
            <p>Manufacturers of</p>
            <h1>
              <Typewriter
                words={[
                  "Hot Air Oven",
                  "Incubator",
                  "Water Bath",
                  "BOD",
                  "and many more!",
                ]}
                loop
                cursor
                cursorStyle="|"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1500}
              />
            </h1>
          </div>
          <button
            onClick={() => about.current.scrollIntoView({ behavior: "smooth" })}
            className="homefront-btn"
          >
            Explore
          </button>
        </div>
      </div>

      <h2 className="about-heading" ref={about}>
        About
      </h2>
      <hr className="heading-bar" />
      <div className="second-div">
        <div className="left">
          <p>
            We’re <span>Onish Scientific Industries</span>, and we’re proud to
            be innovators of the highest standard, using cutting-edge
            technologies to lead the industry forward. Through meticulous and
            reliable work, our company has become known as a top Lab Equipment
            Manufacturer. Have a look at our top sellers and let us know if you
            have any questions about our process.
          </p>
        </div>
      </div>

      <div className="breaker-img">
        <img src={Lab} alt="Lab" className="lab" />
      </div>

      <div className="third-div">
        <h2>Featured Products</h2>
        <hr className="heading-bar" />
        <div className="product-list">
          {featuredProducts.map((product) => {
            return (
              <div key={product.modelNo} className="product">
                <div className="product-imgdiv">
                  <img src={product.image} alt="product" />
                </div>
                <div className="product-body">
                  <h2>{product.productName}</h2>
                  <p>{product.modelNo}</p>
                </div>
                <div className="product-footer">
                  <Link to="/quote">
                    <button className="enquiry-btn">Enquiry</button>
                  </Link>
                  <Link to={`/products/${product.modelNo}`}>
                    <button className="info-btn">Info</button>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="fourth-div">
        <p>
          Any Query about our Products? Reach out today, we’re happy to help.
        </p>
        <Link to="/contact">
          <button>Contact</button>
        </Link>
      </div>

      <div className="fifth-div">
        <div className="card-grid">
          <div className="card">
            <h3 className="card-title">Who we are</h3>
            <div className="card-body">
              <p>
                We, ONISH SCIENTIFIC INDUSTRIES (AXEEM) are a prominent name in
                manufacturing and exporting scientific and laboratory
                instruments.
              </p>
            </div>
            <Link to="who-we-are">
              <button className="card-btn">Read More</button>
            </Link>
          </div>
          <div className="card">
            <h3 className="card-title">What we do</h3>
            <div className="card-body">
              <p>
                Established in 1994, ONISH SCIENTIFIC INDUSTRIES is efficiently
                engrossed in manufacturing and exporting scientific and
                laboratory instruments.
              </p>
            </div>
            <Link to="/here-for-you">
              <button className="card-btn">Read More</button>
            </Link>
          </div>
          <div className="card">
            <h3 className="card-title">Why us</h3>
            <div className="card-body">
              <p>
                As a very well known firm we are absolutely prone to high
                quality end products. We ensure that we use standardized raw
                material for our production processes.
              </p>
            </div>
            <Link to="/why-us">
              <button className="card-btn">Read More</button>
            </Link>
          </div>
        </div>
      </div>

      <div className="sixth-div">
        <h2>Accreditations</h2>
        <hr className="heading-bar" />
        <div className="img-div">
          <img className="img-div-img" src={msme} alt="msme" />
          <img className="img-div-img" src={iso} alt="iso" />
          <img className="img-div-img" src={nsic} alt="nsic" />
          <img className="img-div-img" src={dandb} alt="d&b" />
        </div>
      </div>
    </>
  );
}

export default HomeFront;
