import React, { useEffect } from "react";
import validateData from "../ValidateData";
import useForm from "../useForm";
import tick from "../../assets/Tick.svg";
import manquote from "../../assets/ManQuote.png";
import "./Quote.css";

function Quote({ match }) {
  const { handleChange, data, onFormSubmit, errors, isSubmitted } = useForm(
    validateData,
    "get_quote"
  );

  const { name, email, mobileNo, subject, message } = data;

  useEffect(() => {
    if (match.params.id) {
    }
  }, [match.params.id]);

  // console.log(match.params.id);
  return (
    <>
      <div className="q-container">
        <div className="q-left-container">
          <h1>Get a Quote</h1>
          <h3>Fair Prices, Guaranteed!</h3>
          <hr className="heading-bar" />
          {!isSubmitted ? (
            <form onSubmit={onFormSubmit}>
              <div className="q-form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleChange}
                />
                {errors.name && <small>{errors.name}</small>}
              </div>
              <div className="q-form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                />
                {errors.email && <small>{errors.email}</small>}
              </div>
              <div className="q-form-group">
                <label htmlFor="mobile">Mobile Number</label>
                <input
                  type="tel"
                  name="mobileNo"
                  value={mobileNo}
                  onChange={handleChange}
                />
                {errors.mobileNo && <small>{errors.mobileNo}</small>}
              </div>
              <div className="q-form-group">
                <label htmlFor="subject">Subject</label>
                <input
                  type="text"
                  name="subject"
                  value={subject}
                  onChange={handleChange}
                />
                {errors.subject && <small>{errors.subject}</small>}
              </div>
              <div className="q-form-group">
                <label htmlFor="message">Product Name or Model No.</label>
                <textarea
                  name="message"
                  value={message}
                  onChange={handleChange}
                  cols="43"
                  rows="5"
                ></textarea>
                {errors.message && <small>{errors.message}</small>}
              </div>
              <div className="q-form-group">
                <button type="submit">Submit</button>
              </div>
            </form>
          ) : (
            <div className="form-success">
              <img src={tick} alt="" />
              <h4>Details Successfully Submitted.</h4>
              <h5>Our team will contact you soon.</h5>
            </div>
          )}
        </div>
        <div className="q-right-container">
          <img src={manquote} className="manQuote" alt="ManQuote" />
        </div>
      </div>
    </>
  );
}

export default Quote;
