export default function validateData(data) {
  let errors = {};

  if (!data.name.trim()) {
    errors.name = "Name Required";
  }

  //Email
  if (!data.email) {
    errors.email = "Email required";
  } else if (!/\S+@\S+\.\S+/.test(data.email)) {
    errors.email = "Email address is invalid";
  }

  if (!data.mobileNo) {
    errors.mobileNo = "Mobile Number required";
  } else if (!/^[0-9]+$/.test(data.mobileNo)) {
    errors.mobileNo = "Only numbers are allowed!";
  }

  if (!data.subject.trim()) {
    errors.subject = "Subject Required";
  }

  if (!data.message.trim()) {
    errors.message = "Message Required";
  }

  return errors;
}
