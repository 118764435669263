import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AxeemO from "../../assets/AxeemO.png";
import AxeemW from "../../assets/AxeemW.png";
import "./Header.css";

const handleNavLinks = () => {
  const navbar = document.getElementsByClassName("nav-link-div")[0];
  navbar.classList.toggle("active");
};

function Header() {
  const location = useLocation();

  useEffect(() => {
    const nav = document.getElementsByClassName("nav-link-div")[0];
    if (nav.classList[1] === "active") {
      nav.classList.remove("active");
    }
  }, [location]);

  return (
    <div className={location.pathname === "/" ? "header" : "header shadow"}>
      <div className="brand">
        <Link to="/">
          <img
            src={location.pathname !== "/" ? AxeemO : AxeemW}
            alt="AxeemLab"
          />
        </Link>
      </div>
      <button onClick={handleNavLinks} className="hamburger">
        <span
          className={location.pathname === "/" ? "bar white" : "bar orange"}
        ></span>
        <span
          className={location.pathname === "/" ? "bar white" : "bar orange"}
        ></span>
        <span
          className={location.pathname === "/" ? "bar white" : "bar orange"}
        ></span>
      </button>
      <div className="nav-link-div">
        <span className="cross" onClick={handleNavLinks}>
          X
        </span>
        <div className="nav-links">
          <Link
            className={
              location.pathname === "/" ? "home-link-style li" : "link-style li"
            }
            to="/"
          >
            Home
          </Link>
          <div
            className={
              location.pathname === "/"
                ? "home-link-style li dropdown"
                : "link-style li dropdown"
            }
          >
            About Us
            <div className="dropdown-content">
              <Link to="/who-we-are">Who we are</Link>
              <Link to="/here-for-you">Here for you</Link>
              <Link to="/why-us">Why Us</Link>
            </div>
          </div>
          <Link
            className={
              location.pathname === "/" ? "home-link-style li" : "link-style li"
            }
            to="/products"
          >
            Products
          </Link>
          <div
            className={
              location.pathname === "/"
                ? "home-link-style li dropdown"
                : "link-style li dropdown"
            }
          >
            Features
            <div className="dropdown-content">
              <Link to="/salient-features">Salient Features</Link>
              <Link to="/certifications">Certifications</Link>
            </div>
          </div>
          <Link
            className={
              location.pathname === "/" ? "home-link-style li" : "link-style li"
            }
            to="/contact"
          >
            Contact Us
          </Link>
          <Link
            className={
              location.pathname === "/"
                ? "home-quote-link home-link-style li"
                : "quote-link link-style li"
            }
            to="/quote"
          >
            Get Quote
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
