import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useInfo from "../useInfo";
import "./ProductPage.css";

function ProductPage({ match }) {
  const { products } = useInfo();
  const [item, setItem] = useState({});
  const error = "No Item Found!";

  useEffect(() => {
    products.map((product) => {
      if (product.modelNo === match.params.id) {
        setItem(product);
        return 0;
      }
      return 0;
    });
  }, [match.params.id, products]);

  return (
    <>
      {!item.productName ? (
        <h1>{error}</h1>
      ) : (
        <div className="pp-container">
          <div className="pp-left-container">
            <img src={item.image} alt="" />
          </div>
          <div className="pp-right-container">
            <h1 className="pp-heading">{item.productName}</h1>
            <p className="pp-model">Model No.- {item.modelNo}</p>
            <hr className="pp-break" />
            <p className="pp-description">{item.description}</p>
            {item.table && (
              <img src={item.table} className="pp-table" alt="Specifications" />
            )}
            <button className="pp-btn">
              <Link to={`/quote`} className="pp-btn-link">
                Get Quote
              </Link>
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default ProductPage;
