import React from "react";
import C1 from "../../assets/C1.png";
import "./Certifications.css";

function Certifications() {
  return (
    <div>
      <h1>Certifications</h1>
      <hr className="heading-bar" />
      <div className="image-gallery">
        <p>&#60;</p>
        <img src={C1} alt="Certificate" />
        <p>&#62;</p>
      </div>
    </div>
  );
}

export default Certifications;
